import { captureException } from '@sentry/nextjs';
import { type RefObject, type MutableRefObject } from 'react';
import { formatDuration, intervalToDuration } from 'date-fns';

import { isProductionEnvironment } from 'env';
import { type FormAnswerInput } from 'gql/healthie/types.generated';
import { AgileTreatment, SelfServiceFeaturePath, type UserSSPermissions } from 'app/types';
import { states } from 'components/BookingCheckoutWizard/utils/helpers';
import { type User } from 'lib/DynamoDBClient/types';
import { productSettings } from 'app/(headless-layout)/checkout/config';

import {
  CHECKOUT_COMPLETED_MESSAGE,
  CHECKOUT_EXPIRED_MESSAGE,
  CHECKOUT_NOT_FOUND_MESSAGE,
  RESOURCE_NOT_FOUND_MESSAGE,
  UNEXPECTED_ERROR_MESSAGE,
} from '../../constants';

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const buildUrl = (baseUrl: string, params: Record<string, any>) => {
  const url = new URL(baseUrl);

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      const encodedValue = encodeURIComponent(value);

      url.search = `${url.search}${url.search ? '&' : ''}${key}=${encodedValue}`;
    }
  });

  return url.toString();
};

const logError = (error: any) => {
  return console.error(isProductionEnvironment ? error?.message : error);
};

export function clientFacingErrorHandler(error: any, message?: string) {
  logError(error);

  const messageForClient: string = message || error?.message;

  return { message: messageForClient, data: null };
}

export function errorHandler(error: any, options?: { bubble?: boolean; report?: boolean }) {
  logError(error);

  if (options?.bubble) throw error;

  if (options?.report) {
    captureException(error);
  }
}

export const formValuesToFormAnswerInput = (values: Record<string, string>, userId: string) => {
  return Object.entries(values).reduce<FormAnswerInput[]>(
    (acc, [customModuleId, answer]) => [
      ...acc,
      {
        custom_module_id: customModuleId,
        answer,
        user_id: userId,
      },
    ],
    [],
  );
};

interface ValidateNumericValueSettings {
  validateIsInt?: boolean;
  min?: number;
  max?: number;
}

export const validateNumericValue = (
  value: string,
  { validateIsInt, min, max }: ValidateNumericValueSettings,
) => {
  const numberValue = Number(value);
  // const isNonNumeric = isNaN(numberValue);

  switch (true) {
    case isNaN(numberValue):
    case validateIsInt && !Number.isInteger(numberValue):
    case min && numberValue < min:
    case max && numberValue > max:
      return false;
    default:
      return true;
  }
};

export const getPercent = (total: number, percent: number) => {
  return (total * percent) / 100;
};

export const animateOnScroll = (
  setShow: (value: boolean) => void,
  sectionRef: MutableRefObject<null>,
) => {
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        setShow(true);
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      }
    },
    { threshold: 0.1 },
  );

  if (sectionRef.current) {
    observer.observe(sectionRef.current);
  }

  return () => {
    if (sectionRef.current) {
      observer.unobserve(sectionRef.current);
    }
  };
};

export const handleCheckoutSubmitError = (error: any, fallbackMessage: string) => {
  const message = error?.message;

  switch (message) {
    case CHECKOUT_NOT_FOUND_MESSAGE:
    case CHECKOUT_EXPIRED_MESSAGE:
      return clientFacingErrorHandler(error, CHECKOUT_EXPIRED_MESSAGE);
    default:
      return clientFacingErrorHandler(error, fallbackMessage);
  }
};

export const formatPhoneNumber = (
  phoneString: string,
  format: 'e164' | 'international' | 'national' = 'national',
): string => {
  const phoneDigits = phoneString.replace(/\D/g, '');
  const digitGroups = phoneDigits.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (!digitGroups) {
    throw new Error('Invalid phone number');
  }

  const [, intlCode, areaCode, firstThree, lastFour] = digitGroups;

  switch (format) {
    case 'international':
      return `${intlCode ? '+1 ' : ''}${areaCode}-${firstThree}-${lastFour}`;
    case 'national':
      return `(${areaCode}) ${firstThree}-${lastFour}`;
    case 'e164':
      return `${intlCode ? '+1' : ''}${areaCode + firstThree + lastFour}`;
  }
};

export const scrollToSection = (ref: RefObject<HTMLDivElement>) => {
  window.scroll({
    top: ref.current !== null ? ref.current.offsetTop - 70 : document.body.offsetHeight,
    left: 0,
    behavior: 'smooth',
  });
};

export const getTreatmentName = (slug: string) => {
  switch (slug) {
    case AgileTreatment.SEMAGLUTIDE:
      return 'Injectable Semaglutide';
    case AgileTreatment.ORAL_SEMAGLUTIDE:
      return 'Oral Semaglutide';
    case AgileTreatment.TIRZEPATIDE:
      return 'Injectable Tirzepatide';
    case AgileTreatment.ORAL_TIRZEPATIDE:
      return 'Oral Tirzepatide';
    default:
      return 'N/A';
  }
};

export const getStateName = (stateShortName: string) => {
  const state = states.find(({ shortName }) => shortName === stateShortName.trim());

  return state?.name || stateShortName || 'N/A';
};

export const formatEmail = (email: string) => {
  return email.trim().toLocaleLowerCase();
};

export const formatTimeDifference = (date1: Date, date2: Date): string => {
  const duration = intervalToDuration({ start: date1, end: date2 });

  return formatDuration(duration, { format: ['days', 'hours', 'minutes'] });
};

export const getErrorMessageClient = (error: any) => {
  const predefinedErrors = [
    CHECKOUT_EXPIRED_MESSAGE,
    CHECKOUT_NOT_FOUND_MESSAGE,
    CHECKOUT_COMPLETED_MESSAGE,
  ];

  if (predefinedErrors.includes(error?.message)) {
    return error.message as string;
  }

  return UNEXPECTED_ERROR_MESSAGE;
};

export const formatErrorMessage = (errorMessage: string) => {
  switch (errorMessage) {
    case CHECKOUT_NOT_FOUND_MESSAGE:
    case CHECKOUT_EXPIRED_MESSAGE:
      return "The requested checkout session is expired and/or doesn't exist.";
    case CHECKOUT_COMPLETED_MESSAGE:
      return 'The requested checkout session is already completed. Try logging in the patient portal to access your order.';
    case RESOURCE_NOT_FOUND_MESSAGE:
      return 'Requested resource not found';
    default:
      return 'An unexpected error occurred. Please try again later, or contact support for assistance.';
  }
};

export const getAllowedSelfServicePathnames = ({
  subscription,
}: Pick<User, 'subscription'>): UserSSPermissions => {
  const { subscriptionStatus, cancelAt } = subscription || {};

  const isPendingCancellation = !!cancelAt;

  const defaultAllowedPaths = {
    [SelfServiceFeaturePath.CONTACT]: true,
    [SelfServiceFeaturePath.CANCEL_SUBSCRIPTION]: true,
    [SelfServiceFeaturePath.UPDATE_PAYMENT]: true,
    [SelfServiceFeaturePath.REFILL]: true,
    [SelfServiceFeaturePath.UNDO_CANCELLATION]: false,
    [SelfServiceFeaturePath.REACTIVATE_SUBSCRIPTION]: false,
  };

  switch (true) {
    case subscriptionStatus === 'canceled':
      return {
        ...defaultAllowedPaths,
        [SelfServiceFeaturePath.UPDATE_PAYMENT]: false,
        [SelfServiceFeaturePath.REFILL]: false,
        [SelfServiceFeaturePath.CANCEL_SUBSCRIPTION]: false,
        [SelfServiceFeaturePath.REACTIVATE_SUBSCRIPTION]: true,
      };

    case isPendingCancellation:
      return {
        ...defaultAllowedPaths,
        [SelfServiceFeaturePath.CANCEL_SUBSCRIPTION]: false,
        [SelfServiceFeaturePath.UNDO_CANCELLATION]: true,
      };

    case subscriptionStatus !== 'active':
      return {
        ...defaultAllowedPaths,
        [SelfServiceFeaturePath.REFILL]: false,
      };

    default:
      return defaultAllowedPaths;
  }
};

export function getTreatmentByMedicationName(medicationName: string) {
  switch (medicationName) {
    case 'Injectable Semaglutide':
      return AgileTreatment.SEMAGLUTIDE;

    case 'Oral Semaglutide':
      return AgileTreatment.ORAL_SEMAGLUTIDE;

    case 'Injectable Tirzepatide':
      return AgileTreatment.TIRZEPATIDE;

    case 'Oral Tirzepatide':
      return AgileTreatment.ORAL_TIRZEPATIDE;

    default:
      throw new Error(`Unknown medication name: ${medicationName}`);
  }
}

export function getStripeProductId(value: AgileTreatment) {
  return productSettings[value]?.stripeProductId;
}
