import { AgileTreatment } from 'app/types';
import { isProductionEnvironment } from 'env';

interface CheckoutConfigParams {
  stripeProductId: string;
  healthieProviderId: string;
  healthieAppointmentTypeId: string;
}

type Slug = string;

type ConfigParams = Record<Slug, CheckoutConfigParams>;

export const healthieSettings = isProductionEnvironment
  ? {
      healthieProviderId: '2036741',
      healthieAppointmentTypeId: '306379',
    }
  : {
      healthieProviderId: '376966',
      healthieAppointmentTypeId: '104465',
    };

export const productSettings = isProductionEnvironment
  ? {
      [AgileTreatment.SEMAGLUTIDE]: {
        stripeProductId: 'prod_Pw5cGtNwYXLiNk',
      },
      [AgileTreatment.ORAL_SEMAGLUTIDE]: {
        stripeProductId: 'prod_QRy6l8Fq3VWowL',
      },
      [AgileTreatment.TIRZEPATIDE]: {
        stripeProductId: 'prod_QRy3ZGv10UTOYt',
      },
      [AgileTreatment.ORAL_TIRZEPATIDE]: {
        stripeProductId: 'prod_QRy5Ft3SjamJxm',
      },
    }
  : {
      [AgileTreatment.SEMAGLUTIDE]: {
        stripeProductId: 'prod_Pw5R1uVzN8ElCr',
      },
      [AgileTreatment.ORAL_SEMAGLUTIDE]: {
        stripeProductId: 'prod_QUgvz7f0EODlKb',
      },
      [AgileTreatment.TIRZEPATIDE]: {
        stripeProductId: 'prod_QUgwZLAeCi79YQ',
      },
      [AgileTreatment.ORAL_TIRZEPATIDE]: {
        stripeProductId: 'prod_QUgyXcxy5L94vV',
      },
    };

export const CHECKOUT_CONFIG: ConfigParams = {
  [AgileTreatment.SEMAGLUTIDE]: {
    ...productSettings[AgileTreatment.SEMAGLUTIDE],
    ...healthieSettings,
  },
  [AgileTreatment.ORAL_SEMAGLUTIDE]: {
    ...productSettings[AgileTreatment.ORAL_SEMAGLUTIDE],
    ...healthieSettings,
  },
  [AgileTreatment.TIRZEPATIDE]: {
    ...productSettings[AgileTreatment.TIRZEPATIDE],
    ...healthieSettings,
  },
  [AgileTreatment.ORAL_TIRZEPATIDE]: {
    ...productSettings[AgileTreatment.ORAL_TIRZEPATIDE],
    ...healthieSettings,
  },
};
